
import Vue from "vue";

export default Vue.extend({
  props: {
    is_hidden: Boolean
  },
  methods: {
    toggle(): void {
      this.$emit("toggleHide");
    }
  }
});
